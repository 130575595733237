import { Sidebar } from "components/sidebar";
import { MasterLayout } from "layout/MasterLayout";
import {  useEffect, useContext } from "react";
import { useState } from "react";
import { Spinner } from 'reactstrap'; 
import { DistributorNameContext } from '../components/reports/DistributorNameContext';
import { CurrentReportsContext }  from "components/reports/CurrentReportContext";
import CurrentReports from "service/CurrentReport";
import DistributorInfo from "service/DistributorInfo";
import CommonLoading from "components/reports/CommonLoading";
interface MainProps {
  token: string;
  setToken: (token: string) => void;
}
const Main : React.FC<MainProps> = ({ token, setToken}) => {
  const [isTokenSet, setIsTokenSet] = useState(false);
  const{distributorName} = useContext(DistributorNameContext);
  const{setDistributorName} = useContext(DistributorNameContext)
  const {currentReportData} = useContext(CurrentReportsContext)
  const {setCurrentReportData} = useContext(CurrentReportsContext);

  type Report = {
    reportID: string;
    category: string;
    [key: string]: any; // Include additional fields as needed
  };

  type ReportDataForContext = {
    [reportID: string]: Report;
  };
  useEffect(() => {

   async function handleMessage(event: MessageEvent){
      if (event.data && event.data.token) {
      setToken(event.data.token);
      await currentReports(event.data.token);
      await distributorInfo(event.data.token);
      setIsTokenSet(true);
      } else {
        console.log('Invalid message data:', event.data);
      } 
    }
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [setToken]);

  const currentReports = async (token : string) => { 
    try {
        const reprtsData = await CurrentReports(token); 
        const reportDataForContext: ReportDataForContext = reprtsData.data.reduce(
         (acc: ReportDataForContext, report: Report) => {
             acc[report.reportID] = report;
             return acc;
         },
         {}
       );
        await setCurrentReportData(reportDataForContext);   
    } catch (error) {
        console.error("Error loading data:", error);
     }  
  
  }

  const distributorInfo = async (token : string) => { 
    try {
        const distributorInfo = await DistributorInfo(token); 
        await setDistributorName(distributorInfo.data.distributorName);   
    } catch (error) {
        console.error("Error loading data:", error);
     }  
}

  if (!isTokenSet) {
    return (
      <CommonLoading loadingText="Loading data, please wait..." />      
     );  
  }
  return( 
  
<MasterLayout token={token}/>

);
};

export default Main;
