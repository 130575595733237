export default  async function CurrentReports(token){

    const {
        REACT_APP_API_BASE_URL
      } = process.env;

    const url = REACT_APP_API_BASE_URL + "/reporting/v1/report/currentReports?roles=ADMIN";
    try {
        const response = await fetch(url,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${token}`,
                'User-Agent': 'FdsMobile',
            }
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error; 
    }

}