import SendEmail from "service/SendEmail";
import RichTextEditor from "./RichTextEditor";
import { useState, useEffect, useContext } from "react";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { DistributorNameContext } from "./DistributorNameContext";
import { toast } from "react-toastify";
import CommonLoading from "./CommonLoading";

export default function SendReport({token,reportId,fromDate, toDate, requestParams, reportName, period}){
    // State for form inputs
  const [emailTo, setEmailTo] = useState("");
  const {distributorName} = useContext(DistributorNameContext);
  const [subject, setSubject] = useState(`${distributorName} - ${reportName}`);
  const [value, setValue] = useState(`Hi,<br><br>${reportName} report attached.<br><br>Thanks, ${distributorName}`);
  const [reportTypes, setReportTypes] = useState({
    pdf: false,
    excel: false,
  });
  const [errors, setErrors] = useState("");
  const [subjectValid, setSubjectValid] = useState("");
  const [loading, setLoading] = useState(false);
  const closeModal = () => {
    document.querySelector('[data-bs-dismiss="modal"]')?.click();
  };
  

  const resetForm = () => {
    setEmailTo(""); // Reset email input
    setSubject(`${distributorName} - ${reportName}`); // Reset subject to default
    setValue(`Hi,<br><br>${reportName} report attached.<br><br>Thanks, ${distributorName}`); // Reset body
    setReportTypes({ pdf: false, excel: false }); // Reset report type selections
    setErrors("");
    setSubjectValid("");
  };

  useEffect(() => {
    resetForm();
  }, [reportId, distributorName]);

  const isSubjectValid = () => {
    if(!subject){
      setSubjectValid("Please enter the email subject.");
    return false;
    }

    setSubjectValid("");
    return true;
  }

  // Handle input change and clear validation errors
  const isEmailValid = () => {
    if (!emailTo) {
      setErrors("Please enter the recipient email.");
    return false;
    }
    // Split emails by comma and trim spaces
  const emailArray = emailTo.split(",").map(email => email.trim());

  // Email validation regex (supports most common email formats)
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Check if any email is invalid
  const invalidEmails = emailArray.filter(email => !emailRegex.test(email));
  
  if (invalidEmails.length > 0) {
    setErrors(`Invalid email(s): ${invalidEmails.join(", ")}`);
    return false;
  }
  setErrors("");
  return true;
  };

  // Handle form submission
  const handleSendEmail = async () => {
   
    const checkForEmail = isEmailValid();
    const checkForSubject = isSubjectValid();
    if(!checkForEmail || !checkForSubject){
      return;
    }
    setLoading(true);
    const payload = {
      emailTo,
      subject,
      value,
      reportTypes: Object.keys(reportTypes).filter((type) => reportTypes[type]),
    };
    const selectedReportTypes = Object.keys(reportTypes).filter((type) => reportTypes[type]);

    const reportTypesString = selectedReportTypes.join(',');  // Converts to "pdf, excel"

    try {
      const message = await SendEmail(token, reportId, fromDate, toDate,
         requestParams, emailTo, reportTypesString ? reportTypesString : 'pdf', subject, value, period); 
         resetForm();   
    toast.success(message);
    setLoading(false);
    closeModal();
   

  } catch (error) {
    setLoading(false);
    console.error("Error loading data:", error);
    toast.error("Failed to send email.");
  }    
  };

    return(
        <div className="modal fade modal-lg" tabIndex={-1} id="kt_modal_2">
            
        <div className="modal-dialog">
          <div className="modal-content shadow-none">
            <div className="modal-header">
              <h5 className="modal-title">Send Reports by Email</h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
              <i class="fas fa-times"></i>
              </div>
            </div>
            
            <div className="modal-body">
          <form class="form">
            <div class="form-group row">
              <label class="col-3 col-form-label"> Report Type </label>
              <div class="col-9 col-form-label">
              <div className="mb-10 d-flex">
                 <div className="form-check form-check-custom form-check-solid me-10">
                 <label className="form-check-label" for="flexCheckbox30">
                      <i class="fas fa-file-pdf fs-1 me-5 text-danger"></i> 
                      </label>
                     <input className="form-check-input h-20px w-20px" 
                     type="checkbox" value="" id="flexCheckbox30"
                        checked={reportTypes.pdf}
                        onChange={(e) =>
                          setReportTypes((prev) => ({
                            ...prev,
                            pdf: e.target.checked,
                          }))
                        }/>
                     
                </div>
                <div className="form-check form-check-custom form-check-solid me-10">
                <label className="form-check-label" for="flexCheckbox30">
                    <i class="fas fas fa-file-excel fs-1 me-5 text-success"></i> 
                    </label>
                    <input className="form-check-input h-20px w-20px" 
                    type="checkbox" 
                    value="" 
                    id="flexCheckbox30"
                    checked={reportTypes.excel}
                        onChange={(e) =>
                          setReportTypes((prev) => ({
                            ...prev,
                            excel: e.target.checked,
                          }))
                        }
                     />
                  
                </div> 
             </div>
                
              </div>
          </div>
            <div className="mb-10">
                <label className="form-label required">Email To</label>
                <textarea
                  type="text"
                  className="form-control"
                  value={emailTo}
                  onChange={(e) => setEmailTo(e.target.value)}
                  onBlur={isEmailValid}          
                />
                
                {errors  && <div className="text-danger">{errors}</div>}
            
           </div>
           <div className="mb-10">
                <label className="form-label required">  Subject</label>
                <input
                  type="text"
                  className="form-control"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  onBlur={isSubjectValid}
                />
                {subjectValid && <div className="text-danger">{subjectValid}</div>}
           </div>
           <div className="mb-10">
                <label className="form-label"> Body</label>
               <RichTextEditor value={value} setValue={setValue} onChange={setValue}/>
           </div>
          
          
          </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-primary"
              onClick={handleSendEmail} 
              disabled={loading}
              >
                  {loading? 
                    <CommonLoading loadingText="Sending" type="button"/>
               :"Send"}
              </button>
            </div>
          </div>
        </div>
           
      </div>
    )
}