import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Button } from '@mui/material';
import { useState } from 'react';
import { left } from '@popperjs/core';
export default function DatePickerValue({setToDate,setFromDate,fromDate,toDate, toggleButtonClick}) {
  
  const [dateError, setDateError] = useState("");

  const handleDateValidation = () => {
    if (fromDate && toDate && fromDate.isAfter(toDate)) {
      setDateError("From date must not be later than To date");
    } else {
      setDateError(""); // Clear error if validation passes
      toggleButtonClick(); // Call the search function if validation is passed
    }
  };
  
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker', 'DatePicker']}>
        <div>
        <div className='reportDatePickerFrom'>
        <DatePicker  label="From" value={fromDate}   onChange={(newValue) => setFromDate(newValue)}/>
        </div>
        <div className='reportDatePickerTo'>
        <DatePicker
          label="To"         
          value={toDate}
          onChange={(newValue) => setToDate(newValue)
          }
        />
         
       
        </div>
         <div className='reportDatePickerBtn'>    
        <Button className='p-4' sx={{background:"#000",color:"#fff"}} onClick={handleDateValidation}> Search </Button>
        </div> 
        {dateError && <div className='text-danger fs-7 pt-3'>{dateError}</div>} 
        </div>
      </DemoContainer>      
    </LocalizationProvider>
  );
}