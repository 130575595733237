import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export default function reportTimeDuration(duration, toDateOffset){

        let fromDate, toDate;
        const currentDate = dayjs().utc();  
        if (duration === "MONTH,FIRST,LAST") {
            fromDate = currentDate.subtract(1, "month").startOf("month").set("hour", 0)
            .set("minute", 0).set("second", 0).set("millisecond", 0);
            toDate = currentDate.subtract(1, "month").endOf("month").set("hour", 0)
            .set("minute", 0).set("second", 0).set("millisecond", 0);
        } else if (duration === "YEAR,FIRST,TODAY") {
            fromDate = currentDate.startOf("year");
            toDate = currentDate;
        } else {
            toDate = currentDate;
            fromDate = currentDate.subtract(duration, "day");
        }
        
        const dateMap = new Map();
    dateMap.set("fromDate", fromDate)
    dateMap.set("toDate", toDate);

    return dateMap;
    
}