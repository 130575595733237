import React from 'react';
import Chart from 'react-apexcharts';

const BarChart = ({data, reportName, yAxisLeftParam, yAxisRightParam}) => {

  const getLabels = (data) => {
    return data.map((item) => {
      const startDate = new Date(item["Start Date"]);
      const endDate = new Date(item["End Date"]);
      const diffInDays = (endDate - startDate) / (1000 * 60 * 60 * 24);
  
      // Weekly: 7 days or less
      if (diffInDays <= 7) {
        const startYear = startDate.getFullYear();
        const endYear = endDate.getFullYear();
  
        // If both dates are in the same year
        if (startYear === endYear) {
          return `${startDate.toLocaleDateString("default", {
            month: "short",
            day: "numeric",
          })} - ${endDate.toLocaleDateString("default", {
            month: "short",
            day: "numeric",
          })}, ${startYear}`;
        } else {
          // If dates span across years
          return `${startDate.toLocaleDateString("default", {
            month: "short",
            day: "numeric",
            year: "numeric",
          })} - ${endDate.toLocaleDateString("default", {
            month: "short",
            day: "numeric",
            year: "numeric",
          })}`;
        }
      }
      // Monthly: Span of approximately 1 month
      else if (diffInDays <= 31) {
        return `${startDate.toLocaleDateString("default", { month: "short", year: "numeric" })}`;
      }
      // Yearly: Spans across years
      else {
        return `${startDate.getFullYear()}`;
      }
    });
  };
 
  const leftAxisData = yAxisLeftParam
    ? data.data.map(item => parseFloat(item[yAxisLeftParam]) || 0)
    : [];
  const rightAxisData = yAxisRightParam
    ? data.data.map(item => parseFloat(item[yAxisRightParam]) || 0)
    : [];

    const labels = getLabels(data.data)


    
  const options = {
    chart: {
      type: 'line',
      toolbar: {
        tools: {
          download: true, // Enable download button
        },
        export: {
          png: {
            filename: reportName,
          },
          svg: {
            filename: reportName,
          },
          csv: {
            filename: reportName,
          }, // Set custom file name (without extension)
        },
      },
    },
    stroke: {
      width: [0, 1], // Bar has no border; line has width
    },
    title: {
      text: '',
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [], // Only show data labels for the line series
    },
    labels: labels,
    yaxis: [
      {
        title: {
          text: yAxisLeftParam? yAxisLeftParam : 'Left Axis',
        },
        labels: {
          formatter: (value) => Math.round(value), // Round to the nearest integer
        },
        opposite: false, // Left side
      },
      {
        title: {
          text: yAxisRightParam? yAxisRightParam : 'Right Axis',
        },
        labels: {
          formatter: (value) => Math.round(value), // Round to the nearest integer
        },
        opposite: true, // Right side
      },
    ],
    legend: {
      position: 'bottom',
    },
  };

  const series = [
    {
      name: yAxisLeftParam? yAxisLeftParam : 'Left Axis Data',
      type: 'column',
      data: leftAxisData,
    },
    {
      name: yAxisRightParam? yAxisRightParam : 'Right Axis Data',
      type: 'column',
      data: rightAxisData,
    },
  ];

  return (
      <Chart options={options} series={series} type="line" height={500}  />
  
    );
};

export default BarChart;