export default  async function ExportReport(token,reportId,fromDate, toDate, requestParams, isPdf,period){

  const {
    REACT_APP_API_BASE_URL
  } = process.env;

    const baseUrl = REACT_APP_API_BASE_URL + "/reporting/v1/report/export/" + reportId;
    const params = new URLSearchParams();
    let headerAcceptType = null;

  // Check and add dates only if they exist in requestParams
  if(requestParams) {
  requestParams.forEach(({ paramName}) => {
    if (paramName === "fromDate" && fromDate) {
      params.append("fromDate", fromDate);
    }
    if (paramName === "toDate" && toDate) {
      params.append("toDate", toDate);
    }
    if (paramName === "gmtOffsetValue") {
        params.append("gmtOffsetValue", 0);
      }
    if(paramName === "groupByPeriod") {
      if(period) {
        params.append("groupByPeriod", period)
      } else {
        params.append("groupByPeriod", "WEEK")
      }
    }
  });
  }
  if(isPdf === true){
    params.append("reportTypeSelection", "pdf");
    headerAcceptType = "application/pdf";
  } else{
    params.append("reportTypeSelection", "xlsx")
    headerAcceptType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  }

  const url = `${baseUrl}?${params.toString()}`;
    try {

        const response = await fetch(url,{
            //method: 'GET', // Or 'POST', 'PUT', etc. based on your request type
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${token}`,
                'User-Agent': 'FdsMobile',
                'Accept' : `${headerAcceptType}`,
            }
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const disposition = response.headers.get('Content-Disposition');
        const fileName = disposition
                ? disposition.split('filename=')[1].replace(/"/g, '')  // Extract the filename
                : 'downloaded_report.pdf';
        const data = await response.blob();
        
        return { fileName, data };
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error; // Re-throw the error so the caller can handle it
    }
}