import { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";
import loadData from "service/TopBrands";
import { CurrentReportsContext } from "./CurrentReportContext";
import ExportReport from "service/ExportReport";
import SendReport from "./SendReport";
import { Spinner } from 'reactstrap'; 
import email from '../../assets/images/email.png';
import xls from '../../assets/images/xls.png'
import pdf from '../../assets/images/pdf.png'
import { DistributorNameContext } from "./DistributorNameContext";
import { toast } from "react-toastify";
import CommonLoading from './CommonLoading';

export default function HybridReport({ token, reportId }) {
  const [data, setData] = useState({ data: [] });

  const [toDate, setToDate] = useState(dayjs("2024-11-11"));
  const [fromDate, setFromDate] = useState(dayjs("2024-11-01"));
  const [isEmpty, setIsEmpty] = useState(false);
  const [responseParams, setResponseParams] = useState([]);
  const [displayTotal, setDisplayTotal] = useState(false);
  const {currentReportData} = useContext(CurrentReportsContext);
  const [loading, setLoading] = useState(true);
  const [loadingMessage,setLoadingMessage]=useState()
  let reportConfig = currentReportData[reportId];     
  let displayNames = [];
  const maxLimit = 1000;
  const {distributorName} = useContext(DistributorNameContext);

  const dateFormatter = (inputDate) => {
    let date = new Date(inputDate);
    return `${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${date.getDate().toString().padStart(2, "0")}/${date.getFullYear()}`;
  };


  const checkForRequestParams = () => {
    reportConfig = currentReportData[reportId]
    const requestParams = reportConfig.reportRequestParams;
    let toDatePresent = false;
    let timePeriod = false;
    {requestParams.forEach((param ) => {
      if (param.paramName === "toDate") {
        toDatePresent = true;
      } 
      if (param.paramName === "groupByPeriod") {
        timePeriod = true;
      } 
    })}
    
    if(reportConfig.category === "SALES") {
      setDisplayTotal(true);
    } else {
      setDisplayTotal(false);
    }
  };

  const displayNamesForGridView = ["# of orders for next purchase order",
    "Sales total for next purchase order",
    "Cost total for next purchase order",
    "# of open orders"
];

  function getResponseParams(){
    displayNames = reportConfig.reportResponseParams
    .filter(param => param.isDisplay && !displayNamesForGridView.includes(param.displayName.trim()) && param.paramName !== "START_DATE" && param.paramName !== "END_DATE")
    .sort((a, b) => a.displayOrder - b.displayOrder)
    .map(param => ({
      displayName: param.displayName.trim(),
      dataType: param.dataType,
      showTotal : param.showTotal
    }));
    setResponseParams(displayNames);
  }

  
    const handleDownload = async (isPdf) => {
      setLoading(true)
      loadingMessage("Generating Report, please wait...")
      try {
          checkForRequestParams();
          const { fileName, data } = await ExportReport(token, reportId,
            dateFormatter(fromDate), dateFormatter(toDate),
            reportConfig.reportRequestParams, isPdf); 
          if (fileName) {
          const url = window.URL.createObjectURL(data);
          // Create a temporary link and trigger the download
          const link = document.createElement('a');
          link.href = url;
          link.download = fileName; // Extract file name from path
          document.body.appendChild(link);
          link.click();
          if(isPdf) {
            const newTab = window.open(url, '_blank');
          } 
          window.URL.revokeObjectURL(url); 
          setLoading(false)
        } else {
          toast.error('File not found!');
          setLoading(false)
        }
      } catch (error) {
        console.error('Error fetching the file path:', error);
        toast.error('Failed to download the file.');
      }
    };
  
  const reportData = async (cursor, page, period, pageSize) => { 
    try {
        setLoading(true);

        checkForRequestParams();
        const users = await loadData(token, reportId,dateFormatter(fromDate), 
        dateFormatter(toDate),reportConfig.reportRequestParams, cursor, page ,
         period, pageSize); 
        setData(users); 
        setIsEmpty((users.data.length ===0))   
        getResponseParams();
    } catch (error) {
        console.error("Error loading data:", error);
   } finally {
    setLoading(false);

   }
}
  useEffect(() => {
    //check if report contains chart view 
    reportConfig = currentReportData[reportId]
    reportData(null, 1, null, maxLimit);
    //setIsLoading(false);
  }, [reportId]);

  return (
    <>
      <div className='docs-header align-items-stretch mb-2 py-5 mb-lg-10 pb-0 pt-0 border-gray-300 border-bottom border-bottom-dashed'> 
            <h1 className='page-heading'>{reportConfig.reportDescription}</h1>
            <ol class="breadcrumb breadcrumb-dot text-muted fs-6 fw-semibold">
                <li class="breadcrumb-item"><a href="#" class="">Home</a></li>
                <li class="breadcrumb-item"><a href="#" class="">Reports</a></li>
                <li class="breadcrumb-item text-muted">{reportConfig.reportDescription} </li>
            </ol>
      </div>
        <div className="card"> {loading ?  <div className="card-body">
          <CommonLoading loadingText={loadingMessage} />     
        </div> 
:<>       <div className="card-header">
            <div className="card-title"> 
            
            </div>
            <div className="card-toolbar ">

<div className="card-toolbar reportBtn-group">
  {reportConfig.allowPDF ?
    <button type="button"  className="btn ml-16 mr-16" onClick={() => handleDownload(true)}>
    <img src={pdf} alt="Download PDF"/> 
    </button>  : <></>}
    {reportConfig.allowEXCEL ? 
    <button type="button" className="btn ml-16" onClick={() => handleDownload(false)}>
    <img src={xls} alt="Download Excel"  />
    </button> : <></> }
    <button type="button" className="btn ml-16"
      data-bs-toggle="modal" data-bs-target="#kt_modal_2">
      <img src={email} alt="Send Email"  />
    </button>
    <SendReport token={token} reportId={reportId} fromDate={dateFormatter(fromDate)} 
      toDate={dateFormatter(toDate)} requestParams={reportConfig.reportRequestParams} 
      reportName={reportConfig && reportConfig.reportDescription ? reportConfig.reportDescription : "Sales Trend"} null
    />
  </div> 
        </div>
          </div>
      
          <>
          {isEmpty ? 
          <div className="card-body text-center"> <p>No Records Found!</p> </div>
          :
          <div>
                <div className="card-body bg-light-info p-0" >
                  {/* <div className='row'>
                    {data.map((object) => (
                        <div className='col-xl-3 mt-7' key={object.RN}>
                          <div className='card card-custom card-stretch gutter-b '>
                            <div className='card-body'>
                              <p className='font-weight-bolder fs-5 text-blue min-h-40px'>  {object.Metric}</p>
                              <h2 className='card-title font-weight-bolder  font-size-h2 mb-0 mt-6 d-block text-dark'> {object.MetricValue ? object.MetricValue : 0}</h2>
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </div> */}
                  <div className="row">
                    <div className="table-responsive">
                      <table className="table table-striped gy-7 gs-7 mb-0 todaysStatusTable ">
                            <tbody>

                            {displayNamesForGridView.map((object,index) => (
                             //<p>{object}</p>
                                //const item = data.data[0];
                                  <tr key={index}>
                                    <td className="text-end col-md-6"> <span className='fw-bold'>{object}:</span></td>
                                    <td className="text-start fw-bold" style={{paddingLeft : "20px !important"}}><span className="fw-bold">
                                           {(object == 'Sales total for next purchase order'
                                             || object == 'Cost total for next purchase order') ? data.data[0][object] ?
                                              data.data[0][object] : '$ 0' : data.data[0][object]}
                                     </span></td>
                                  </tr>
                            ))}
                            </tbody>
                        </table>
                      </div>
                  </div>
                </div>
          <div className="card-body hover-scroll-x" style={{paddingTop: "8px" , paddingBottom: "8px"}} >
              {/* Conditional Rendering */}
          <table className="table table-rounded table-bordered table-row-bordered border gy-7 gs-7 reportTableView mt-8">
            <thead>
              <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                <th   className="align-top" 
                >#</th>
                
                
                {responseParams.map((item, index) => (
                <th key={index}   className="align-top" 
                style={{ width: ['Description', 'Brand'].includes(item.displayName) === 'Description' ? '300px' : '' }}>
                {item.displayName}
                </th>
                ))}


              </tr>
            </thead>
            <tbody>
              {data.data.length > 0 ? (
                <>
                   { data.data.map((row, index) => (
                  <tr key={index} style={{textAlign: "center"}}>
                    <td>{index + 1}</td>
                    {responseParams.map((item, rIndex) => (
                      <td key={rIndex}
                        style={{
                        textAlign: item.displayName === "Created On"
                        ? "center" 
                        : item.displayName === "Status"
                        ? "center"
                        : item.dataType === "java.lang.String"
                        ? "left"
                        : item.dataType === "java.lang.Long"
                        ? "center"
                        : "right"
                        }}>{  (item.displayName === "Period") ?
                  
                             `${new Date(row["Start Date"]).toLocaleDateString()} -
                              ${new Date(row["End Date"]).toLocaleDateString()}`
                              : 
                              row[item.displayName]
                            }
                      </td>
                    ))}
                  </tr>

                ))}
                {displayTotal ? 
                <tr style={{ fontWeight: "bold", textAlign: "center" }}>
          <td>Total</td>
          {responseParams.map((item, index) => (
            <td
              key={index}
              style={{
                textAlign: item.dataType === "java.lang.Double" ? "right" : "center",
              }}
            >
             {item.showTotal
                ? data.data.reduce((sum, row) => {
                    const value = row[item.displayName];
                    if(item.dataType === "java.lang.Double") {
                      return sum + (value ? parseFloat(value) : 0);
                    } else {
                      return sum + (value ? parseInt(value, 10) : 0);
                    } // Convert to number and add
                  }, 0).toFixed(item.dataType === "java.lang.Double" ? 2 : 0) // Format to 2 decimal places for consistent display
                : ""}
            </td>
          ))}
        </tr> : <></>}
      </>
                ) : (
                <tr>
                  <td colSpan="6" style={{ textAlign: "center" }}>
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          </div>
          </div>
        }
          </>
          
          </> }  
           
        </div>
    </>
  );
}