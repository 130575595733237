import { FC, useState , useContext} from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";

import Main from "pages/Main";
import { App } from "App";
import TableReport from "components/reports/TableReport";
import { DistributorNameContext } from "components/reports/DistributorNameContext";
import { CurrentReportsContext } from "components/reports/CurrentReportContext";
import CardReport from "components/reports/CardReport";
import HomePage from "components/reports/HomePage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HybridReport from "components/reports/HybridReport";

const { PUBLIC_URL } = process.env;

const AppRoutes: FC = () => {

  interface Report {
    reportID: number;
    reportDescription: string;
    outputType: 'TABULAR' | 'CARD' | 'HYBRID';
  }

  const [token, setToken] = useState("");
  const [currentReportData, setCurrentReportData] = useState([]);
  const [distributorName, setDistributorName] = useState([]);
  return (
    <CurrentReportsContext.Provider value={{currentReportData, setCurrentReportData}}>     
    <DistributorNameContext.Provider value={{distributorName, setDistributorName}}>
    <BrowserRouter basename={PUBLIC_URL}>
    <ToastContainer position="top-center" autoClose={3000} hideProgressBar closeOnClick/>
      <Routes>
        <Route element={<App />}>
        <Route path="/" element={<HomePage/>}></Route>
        <Route path="report" element={<Main  token={token} setToken={setToken} />}>        
        {Object.entries(currentReportData).map(([reportId, data]) => {
          const typedReport = data as Report;

          let ElementComponent: React.FC<{ token: string; reportId: number }> | null = null;

          // Determine the component to use based on the reportType
          if (typedReport.outputType.includes('TABULAR')) {
            ElementComponent = TableReport;
          } else if (typedReport.outputType.includes('CARD')) {
            ElementComponent = CardReport;
          } else if (typedReport.outputType.includes('HYBRID')) {
            ElementComponent = HybridReport;
          } else {
            ElementComponent = null; // Handle undefined or unknown types gracefully
          }
          
          // Special handling for the index route (report ID 101)
          if (Number(reportId) === 101) {
            return (
              <Route
                key={reportId}
                index
                element={<CardReport token={token} reportId={Number(reportId)} />}
              />
            );}     
       // Only render the route if a valid component is found      
          return( ElementComponent && <Route
            key={reportId}
            path={typedReport.reportDescription.replaceAll(" ", "-")}
            element={<ElementComponent token={token} reportId={Number(reportId)} />}
          />
        );
          
        })}

          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
    </DistributorNameContext.Provider>
    </CurrentReportsContext.Provider>
  );

  
};

export { AppRoutes };
