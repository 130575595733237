import { SidebarMenuMain } from "./SidebarMenuMain";
import ReportNavigation from "components/reports/ReportNavigation";
import React, { useEffect, useState } from 'react';

const SidebarMenu: React.FC<{ token: string }> = ({ token })  => {
  return (
    

    <div className="app-sidebar-menu overflow-hidden flex-column-fluid">
      <div
        className="app-sidebar-wrapper hover-scroll-overlay-y my-5"
        data-kt-scroll="true"
        data-kt-scroll-activate="true"
        data-kt-scroll-height="auto"
        data-kt-scroll-dependencies="#n_one_app_sidebar_logo, #kt_app_sidebar_footer"
        data-kt-scroll-wrappers="#kt_app_sidebar_menu"
        data-kt-scroll-offset="5px"
        data-kt-scroll-save-state="true"
      >
        <div
          className="menu menu-column menu-rounded menu-sub-indention px-3"
          id="#kt_app_sidebar_menu"
          data-kt-menu="true"
          data-kt-menu-expand="false"
        >
       <ReportNavigation token={token}/>
          <div style={{ width: "100%", height: "50px" }}></div>
        </div>
      </div>
    </div>
    
  );
};

export { SidebarMenu };
