import React, {useState, useEffect} from 'react';
import dayjs from "dayjs";
import DatePickerValue from "./DatePickerValue";
import ExportReport from 'service/ExportReport';
import SendReport from './SendReport';
import loadData from 'service/TopBrands';
import email from '../../assets/images/email.png';
import xls from '../../assets/images/xls.png'
import pdf from '../../assets/images/pdf.png'
import { DistributorNameContext } from './DistributorNameContext';
import { CurrentReportsContext } from "./CurrentReportContext";
import { useContext } from 'react';
import { Spinner } from 'reactstrap';
import { toast } from "react-toastify";
import CommonLoading from './CommonLoading';

export default function CardReport({token, reportId}) {

  const currentDate = dayjs();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isToDateAvailable, setIsToDateAvailable] = useState(false);
  const [loadingMessage,setLoadingMessage]=useState("Loading Report, please wait...")
  const [toDate, setToDate] = useState(currentDate);
  const [fromDate, setFromDate] = useState(dayjs(currentDate.subtract(7, "day")));
  const {distributorName} = useContext(DistributorNameContext);
  const {currentReportData} = useContext(CurrentReportsContext);
  let reportConfig = currentReportData[reportId];     

  //date formatter
  const dateFormatter = (inputDate) => {
    let date = new Date(inputDate);
    return `${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${date.getDate().toString().padStart(2, "0")}/${date.getFullYear()}`;
  };

  //check for submit button
  const toggleButtonClick = () => {
    // Placeholder for search toggle functionality
    setIsSubmit(!isSubmit)
  };

  //check for request param from report config
  const checkForRequestParams = () => {
    reportConfig = currentReportData[reportId];
    const requestParams = reportConfig.reportRequestParams;
    let toDatePresent = false;
    {requestParams.forEach((param ) => {
      if (param.paramName === "toDate") {
        toDatePresent = true;
      } 
    })}
    if(toDatePresent) {
      setIsToDateAvailable(true);
    } else{
      setIsToDateAvailable(false);
    }
  };

  //download pdf/excel
  const handleDownload = async (isPdf) => {
    setLoadingMessage("Generating Report, please wait...")
    setLoading(true)
    try {
        //checkForRequestParams();
        const { fileName, data } = await ExportReport(token, reportId, 
          dateFormatter(fromDate), dateFormatter(toDate), 
          reportConfig.reportRequestParams, isPdf); 
        if (fileName) {
          setLoading(false)
          const url = window.URL.createObjectURL(data);
        // Create a temporary link and trigger the download
          const link = document.createElement('a');
          link.href = url;
          link.download = fileName; // Extract file name from path
          document.body.appendChild(link);
          link.click();
          if(isPdf) {
            const newTab = window.open(url, '_blank');
          } 
          window.URL.revokeObjectURL(url);

        } else {

          setLoading(false)
          toast.error('File not found!');
        }
    } catch (error) {
      console.error('Error fetching the file path:', error);
      toast.error('Failed to download the file.');

          setLoading(false)
    }
  };
 
  //call api for reports data
  useEffect(() => {
    const todayReport = async () => { 
      try {
        checkForRequestParams();
        const reprtsData = await loadData(token, reportId,dateFormatter(fromDate), 
                dateFormatter(toDate), reportConfig.reportRequestParams, null, 1 ,
                 null, null); 
        setData(reprtsData.data) ;
        setLoading(false)
      } catch (error) {
          console.error("Error loading data:", error);
      }
    }
    todayReport()
  }, [isSubmit,reportId]);
  
  return(

          <div>
            <div className='docs-header align-items-stretch mb-2 py-5 mb-lg-10 border-gray-300 border-bottom border-bottom-dashed'> 
              <h1 className='page-heading'>{reportConfig.reportDescription} </h1>
                <ol className="breadcrumb breadcrumb-dot text-muted fs-6 fw-semibold">
                  <li className="breadcrumb-item"><a href="#" >Home</a></li>
                  <li className="breadcrumb-item"><a href="#" >Reports</a></li>
                  <li className="breadcrumb-item text-muted">{reportConfig.reportDescription} </li>
                </ol>
            </div>

            <div> 
            {loading ? (
                  <div className="card">
                     <CommonLoading loadingText={loadingMessage}/>
                  </div>
            ) : (
              <div className="card">
              
                <div className="card-header">
                  <div className="card-title"> 
                    <div className="reportSearch">
                      {(isToDateAvailable) ? 
                        <DatePickerValue
                          setToDate={setToDate}
                          setFromDate={setFromDate}
                          fromDate={fromDate}
                          toDate={toDate}
                          toggleButtonClick={toggleButtonClick}
                        /> 
                        : <></>
                      }
                    </div>
                    </div>
                    <div className='card-toolbar'>
                    <div className="card-toolbar reportBtn-group">
                      {reportConfig.allowPDF ? 
                    <button type="button" className="btn" onClick={() => handleDownload(true)}>
                    <img src={pdf} alt="Download PDF"/>  
                    </button> : <></> }
                    {reportConfig.allowEXCEL ? 
                    <button type="button" className="btn" onClick={() => handleDownload(false)}>
                    <img src={xls} alt="Download Excel"  />
                    </button> :<></> }
                    <button type="button"   data-bs-toggle="modal"
                      data-bs-target="#kt_modal_2" className="btn">
                      <img src={email} alt="Send Email"  />
                    </button>
                      <SendReport  token={token} reportId={reportId} fromDate={dateFormatter(fromDate)} 
                        toDate={dateFormatter(toDate)} requestParams={reportConfig.reportRequestParams}
                        reportName={reportConfig.reportDescription} null
                      />
                      </div>
                 
                      </div>
                  
        
                </div>

                {/* <div className="card-header" >
                <div className="card-toolbar"> 
</div>
                
                  <h3 className="card-title" style={{justifyContent:"center", alignItems:"center", textAlign:"center"}}>
                   {distributorName}  {reportConfig.reportDescription}  */}
                   {/* <br /> 
                    {isToDateAvailable && (
                      <>
                        {" "}from {dateFormatter(fromDate)} to {dateFormatter(toDate)}
                      </>    
                    )}  */}
                  {/* </h3>
                  <div className="card-toolbar">
            
                  </div>
                </div> */}
        
                <div className="card-body bg-light-info p-0" >
                  {/* <div className='row'>
                    {data.map((object) => (
                        <div className='col-xl-3 mt-7' key={object.RN}>
                          <div className='card card-custom card-stretch gutter-b '>
                            <div className='card-body'>
                              <p className='font-weight-bolder fs-5 text-blue min-h-40px'>  {object.Metric}</p>
                              <h2 className='card-title font-weight-bolder  font-size-h2 mb-0 mt-6 d-block text-dark'> {object.MetricValue ? object.MetricValue : 0}</h2>
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </div> */}
                  <div className="row">
                    <div className="table-responsive">
                      <table className="table table-striped gy-7 gs-7 mb-0 todaysStatusTable">
                            <tbody>
                            {data.map((object) => (
                                  <tr key={object.RN}>
                                    <td className="text-end col-md-6"> <span className='fw-bold'>{object.Metric}</span></td>
                                    <td className="text-start fw-bold" style={{paddingLeft : "20px !important"}}><span className="fw-bold">
                                           {object.MetricValue ? object.MetricValue : 0}
                                     </span></td>
                                  </tr>
                               ))}
                            </tbody>
                        </table>
                      </div>
                  </div>
                </div>

              </div>
            )}
            </div>
          </div>
        
        )
}