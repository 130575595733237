import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { LayoutProvider } from "./layout/core";
import { MasterMenuComponent } from "./layout/MasterMenuComponent";
import { ThemeModeProvider } from "./hooks‬/theme";
import { MasterComponentInit } from "./layout/MasterComponentInit";
//import { AuthInit } from "hooks‬/Auth";
//import { LocationProvider } from "hooks‬/LocationContext";

function App()  {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <LayoutProvider>
        <ThemeModeProvider>
          {/* <AuthInit> */}
            {/* <LocationProvider> */}
              <Outlet />
              <MasterMenuComponent />
              <MasterComponentInit />
           {/* </LocationProvider> */}
          {/* </AuthInit> */}
        </ThemeModeProvider>
      </LayoutProvider>
    </Suspense>
  );
};

export { App };
