export default  async function loadData(token,reportId,fromDate, 
  toDate, requestParams, cursor, page, period, rowsPerPage){

    const {
      REACT_APP_API_BASE_URL
    } = process.env;
    const baseUrl = REACT_APP_API_BASE_URL + "/reporting/v1/report/data/" + reportId;

    const params = new URLSearchParams();

  // Check and add dates only if they exist in requestParams
  requestParams.forEach(({ paramName}) => {
    if (paramName === "fromDate" && fromDate) {
      params.append("fromDate", fromDate);
    }
    if (paramName === "toDate" && toDate) {
      params.append("toDate", toDate);
    }
    if (paramName === "gmtOffsetValue") {
        params.append("gmtOffsetValue", 0);
      }
    if(paramName === "groupByPeriod") {
      if(period) {
        params.append("groupByPeriod", period)
      } else {
        params.append("groupByPeriod", "WEEK")
      }
      
    }
  });

  if(cursor) {
    params.append("cursor", cursor)
  } else {
    params.append("pageNo", page-1)
  }

  if(!cursor && rowsPerPage) {
    params.append("limit", rowsPerPage)

  }

  const url = `${baseUrl}?${params.toString()}`;
    try {

        const response = await fetch(url,{
            //method: 'GET', // Or 'POST', 'PUT', etc. based on your request type
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${token}`,
                'User-Agent': 'FdsMobile',
            }
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error; // Re-throw the error so the caller can handle it
    }
}