import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import "./i18n/config";
import "./assets/fonticon/fonticon.css";
import "./assets/keenicons/duotone/style.css";
import "./assets/keenicons/outline/style.css";
import "./assets/keenicons/solid/style.css";
import "./assets/sass/style.scss";
import "./assets/sass/plugins.scss";
import "./assets/sass/style.react.scss";
import "./assets/app.css";

import { AppRoutes } from "./routing/AppRoutes";
const queryClient = new QueryClient();
const container = document.getElementById("root");

if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <AppRoutes />
    </QueryClientProvider>
  );
}
