import { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";
import DatePickerValue from "./DatePickerValue";
import loadData from "service/TopBrands";
import { CurrentReportsContext } from "./CurrentReportContext";
import ExportReport from "service/ExportReport";
import SendReport from "./SendReport";
import { Spinner } from 'reactstrap'; 
import email from '../../assets/images/email.png';
import xls from '../../assets/images/xls.png'
import pdf from '../../assets/images/pdf.png'
import { DistributorNameContext } from "./DistributorNameContext";
import AxisDropdowns from "./AxisDropdowns";
import { toast } from "react-toastify";
import reportTimeDuration from "./ReportTimeDuration";
import CommonLoading from "./CommonLoading";

export default function TableReport({ token, reportId }) {
  const [view, setView] = useState('table');
  const [isChartAvailable, setIsChartAvailable] = useState(false);
  const [data, setData] = useState({ data: [] });
  const [isSubmit, setIsSubmit] = useState(false); 
  const [isToDateAvailable, setIsToDateAvailable] = useState(false);
  const [istimeIntervalAvailable, setIstimeIntervalAvailable] = useState(false);
  const currentDate = dayjs();
  
  const [currentPage, setCurrentPage] = useState(1);
  const [responseParams, setResponseParams] = useState([]);
  const [cursors, setCursors] = useState({});
  const [totalPages, setTotalPages] = useState(1);
  const [defaultPageSize, setDefaultPageSize] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [displayTotal, setDisplayTotal] = useState(false);
  const {currentReportData} = useContext(CurrentReportsContext);
  const [loading, setLoading] = useState(true);
  const [loadingMessage,setLoadingMessage]=useState("")
  const [defaultPeriod, setDefaultPeriod] = useState("WEEK");
  let reportConfig = currentReportData[reportId];     
  let displayNames = [];
  let duration = reportConfig.duration;
  const result = reportTimeDuration(reportConfig.duration, reportConfig.toDateOffset);
  const [toDate, setToDate] = useState(result.get("toDate"));
  const [fromDate, setFromDate] = useState(result.get("fromDate"));
  const maxLimit = 1000;
  const {distributorName} = useContext(DistributorNameContext);

  //date formatter
  const dateFormatter = (inputDate) => {
    let date = new Date(inputDate);
    return `${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${date.getDate().toString().padStart(2, "0")}/${date.getFullYear()}`;
  };

  //check for submit
  const toggleButtonClick = () => {
    setIsSubmit(!isSubmit)
  };


  //paginaton
  const handlePageClick = (page, cursor = null) => {  
    reportData(cursor, page, false,rowsPerPage, false, null, null)
  };

  //check request params from report config
  const checkForRequestParams = () => {
    reportConfig = currentReportData[reportId]
    const requestParams = reportConfig.reportRequestParams;
    let toDatePresent = false;
    let timePeriod = false;
    {requestParams.forEach((param ) => {
      if (param.paramName === "toDate") {
        toDatePresent = true;
      } 
      if (param.paramName === "groupByPeriod") {
        timePeriod = true;
      } 
    })}
    if(toDatePresent) {
      setIsToDateAvailable(true);
    } else{
      setIsToDateAvailable(false);
    }
    if(timePeriod) {
      setIstimeIntervalAvailable(true);
    } else {
      setIstimeIntervalAvailable(false);
    }
    if(reportConfig.category === "SALES") {
      setDisplayTotal(true);
    } else {
      setDisplayTotal(false);
    }
  };

  //get response param from report config
  function getResponseParams(){
    displayNames = reportConfig.reportResponseParams
    .filter(param => param.isDisplay && param.paramName !== "START_DATE" && param.paramName !== "END_DATE")
    .sort((a, b) => a.displayOrder - b.displayOrder)
    .map(param => ({
      displayName: param.displayName.trim(),
      dataType: param.dataType,
      showTotal : param.showTotal
    }));

    if (reportConfig.reportID === 1005) {
      displayNames.unshift({
        displayName: "Period",
        dataType: "java.lang.String",
        showTotal : false
      });
    }
    setResponseParams(displayNames);
  }

  //check for periods (weekly,monthly,yearly) for chart view
  const handleChangeTimeInterval = (event) => {
    setDefaultPeriod(event.target.value);
    reportData(null, 1, event.target.value, false, defaultPageSize, false, null, null)
  }
 
  
    //download pdf/excel
    const handleDownload = async (isPdf) => {
      setLoadingMessage("Generating Report, please wait...")
      setLoading(true)
      try {
          checkForRequestParams();
          const { fileName, data } = await ExportReport(token, reportId,
            dateFormatter(fromDate), dateFormatter(toDate),
            reportConfig.reportRequestParams, isPdf, defaultPeriod); 
          if (fileName) {
          const url = window.URL.createObjectURL(data);
          // Create a temporary link and trigger the download
          const link = document.createElement('a');
          link.href = url;
          link.download = fileName; // Extract file name from path
          document.body.appendChild(link);
          link.click();
          if(isPdf) {
            const newTab = window.open(url, '_blank');
          }
          window.URL.revokeObjectURL(url); 
          setLoading(false)
        } else {
          toast.error('File not found!');
          setLoading(false)
        }
      } catch (error) {
        console.error('Error fetching the file path:', error);
        toast.error('Failed to download the file.');
      }
    };
  
    //check between chart view and table view
    const handleViewChange = (selectedView) => {
      setView(selectedView);
    };

    //check for page size change
    const handlePagesizeChange = (pageSize) => {
      setRowsPerPage(pageSize)
      setDefaultPageSize(pageSize)
      reportData(null, 1, null, true, pageSize, false, null, null);
    }

    //call for api to get report data
  const reportData = async (cursor, page, period, ispageSizeChange, pageSize, isIntialLoad, initialFromDate, initialToDate) => { 
    try {
        setLoading(true);
        let limit = ispageSizeChange ? pageSize : rowsPerPage;
        checkForRequestParams();
        const users = await loadData(token, reportId,isIntialLoad ? dateFormatter(initialFromDate) : dateFormatter(fromDate), 
        isIntialLoad ? dateFormatter(initialToDate) : dateFormatter(toDate),reportConfig.reportRequestParams, cursor, page ,
         period, limit ? limit : rowsPerPage); 
        setData(users);
        setCurrentPage(page);
        setTotalPages(Math.ceil(users.total / (limit ? limit : rowsPerPage)));
        setCursors({next: users.cursors.find(cursor => cursor.rel === "next")?.value,
        previous: users.cursors.find(cursor => cursor.rel === "previous")?.value,});
        getResponseParams();
    } catch (error) {
        console.error("Error loading data:", error);
   } finally {
    setLoading(false);

   }
}


  const setIntialLoadValues= (isIntialLoad, initialFromDate, initialToDate) => {
    reportConfig = currentReportData[reportId]
    const outputTypes = reportConfig.outputType.split(',').map(type => type.trim()); 
    if(outputTypes.includes('LINE_CHART')) {
      setView('chart');
      setIsChartAvailable(true);
      setRowsPerPage(maxLimit);
      setDefaultPeriod("WEEK");
      reportData(null, 1, null, true, maxLimit, isIntialLoad, initialFromDate, initialToDate);
    } else {
      setView('table');
      setIsChartAvailable(false);
      setRowsPerPage(10);
      setDefaultPageSize(10);
      reportData(null, 1, null, true, 10, isIntialLoad, initialFromDate, initialToDate);
    }
}

//initial load
useEffect(() => {
  //set from date and to date based upon duration
  const result = reportTimeDuration(reportConfig.duration, reportConfig.toDateOffset);
  setFromDate(result.get("fromDate"));
  setToDate(result.get("toDate"));
  setIntialLoadValues(true,result.get("fromDate"),result.get("toDate") );
  }, [reportId] )


  useEffect(() => {
    setIntialLoadValues(false, null, null);
  }, [isSubmit]);

 

  return (
    <>
      <div className='docs-header align-items-stretch mb-2 py-5 mb-lg-10 pb-0 pt-0 border-gray-300 border-bottom border-bottom-dashed'> 
            <h1 className='page-heading'>{reportConfig.reportDescription}</h1>
            <ol class="breadcrumb breadcrumb-dot text-muted fs-6 fw-semibold">
                <li class="breadcrumb-item"><a href="#" class="">Home</a></li>
                <li class="breadcrumb-item"><a href="#" class="">Reports</a></li>
                <li class="breadcrumb-item text-muted">{reportConfig.reportDescription} </li>
            </ol>
      </div>
        <div className="card">
          {loading?<div className="card-body">
           <CommonLoading loadingText={loadingMessage} />   
         </div>:<>
          <div className="card-header">
            <div className="card-title"> 
              <div className="reportSearch">
               {(isToDateAvailable) ?  
                <DatePickerValue
                  setToDate={setToDate}
                  setFromDate={setFromDate}
                  fromDate={fromDate}
                  toDate={toDate}
                  toggleButtonClick={toggleButtonClick}
                /> : <></>}
              </div>
            </div>
            <div className="card-toolbar ">

<div className="card-toolbar reportBtn-group">
    { reportConfig.allowPDF ? 
    <button type="button"  className="btn ml-16 mr-16" onClick={() => handleDownload(true)}>
    <img src={pdf} alt="Download PDF"/> 
    </button> : <></> }
    { reportConfig.allowEXCEL ?
    <button type="button" className="btn ml-16" onClick={() => handleDownload(false)}>
    <img src={xls} alt="Download Excel"  />
    </button> : <></> }
    <button type="button" className="btn ml-16"
      data-bs-toggle="modal" data-bs-target="#kt_modal_2">
      <img src={email} alt="Send Email"  />
    </button>
    <SendReport token={token} reportId={reportId} fromDate={dateFormatter(fromDate)} 
      toDate={dateFormatter(toDate)} requestParams={reportConfig.reportRequestParams} 
      reportName={reportConfig && reportConfig.reportDescription ? reportConfig.reportDescription : "Sales Trend"} defaultPeriod
    />
  </div> 
  


           
            <div className="d-flex gap-2 min-w-100px p-6">
          { !isChartAvailable && view !== 'chart' && 
                <>
                <label className="mt-3 ml-3"> Records Per Page:   </label> 
                 <select
                  id="records-dropdown"
                  className="form-select w-auto"
                  value={defaultPageSize}
                  onChange={(e) =>{
                    handlePagesizeChange(Number(e.target.value))}}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={200}>200</option>
                  <option value={500}>500</option>


                </select>
                </>
          }       
                  { (istimeIntervalAvailable) ?  <select
                    id="time-dropdown"
                    className="form-select"
                    value={defaultPeriod}
                    onChange={handleChangeTimeInterval}
                  >
                    <option value="WEEK">Weekly</option>
                    <option value="MONTH">Monthly</option>
                    <option value="YEAR">Yearly</option>
                  </select>
                  : <></>
                  }
                    </div>

                   {!isChartAvailable && view !== 'chart' &&
                    
                   <div className="d-flex gap-2 min-w-100px pr-6 pt-6 pb-6 align-item-center justify-content-center">
          Total Records : {data.total}
                    </div> }
          

                    <div className="card-toolbar"> 
            { isChartAvailable ?
          <div className="btn-group">

          <button
            type="button"
            className={`btn btn-sm ${view === 'chart' ? 'btn-dark' : 'btn-light'}`}
            onClick={() => handleViewChange('chart')}
          >
            Chart
          </button>
          <button
            type="button"
            className={`btn btn-sm ${view === 'table' ? 'btn-dark' : 'btn-light'}`}
            onClick={() => handleViewChange('table')}
          >
            Table
          </button>
        </div> : <></>
          }
          </div>
                    </div>
          </div>
          {/* <div className="card-header">
          <div className="card-toolbar"></div>
          <h3 className="card-title" style={{justifyContent:"center", alignItems:"center", textAlign:"center"}}>
            
            {distributorName} {reportConfig.reportDescription}   */}
            {/* <br /> {isToDateAvailable && (
              <>
                {" "}from {dateFormatter(fromDate)} to {dateFormatter(toDate)}
              </>    
            )}  */}

          {/* </h3>
          
        </div> */}
      
          <div className="card-body hover-scroll-x" style={{paddingTop: "2px" , paddingBottom: "2px"}} >
              {/* Conditional Rendering */}
              {isChartAvailable && view === 'chart' ? (
              
              <AxisDropdowns data={data} reportName={reportConfig.reportDescription} reportResponseParams={reportConfig.reportResponseParams}/>
              
            ) : (
          <table className="table table-rounded table-bordered table-row-bordered border gy-7 gs-7 reportTableView">
            <thead>
              <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                <th   className="align-top" 
                >#</th>
                
                
                {responseParams.map((item, index) => (
                <th key={index}   className="align-top" 
                style={{ width: ['Description', 'Brand'].includes(item.displayName) === 'Description' ? '300px' : '' }}>
                {item.displayName}
                </th>
                ))}


              </tr>
            </thead>
            <tbody>
              {data.data.length > 0 ? (
                <>
                   { data.data.map((row, index) => (
                  <tr key={index} style={{textAlign: "center"}}>
                    <td>{(currentPage - 1) * rowsPerPage + index + 1}</td>
                    {responseParams.map((item, rIndex) => (
                      <td key={rIndex}
                        style={{
                        textAlign: item.displayName === "Period"
                        ? "center"
                        : item.dataType === "java.lang.String"
                        ? "left"
                        : item.dataType === "java.lang.Long"
                        ? "center"
                        : "right"
                        }}>{  (item.displayName === "Period") ?
                  
                             `${new Date(row["Start Date"]).toLocaleDateString()} -
                              ${new Date(row["End Date"]).toLocaleDateString()}`
                              : 
                              row[item.displayName]
                            }
                      </td>
                    ))}
                  </tr>

                ))}
                {displayTotal ? 
                <tr style={{ fontWeight: "bold", textAlign: "center" }}>
          <td>Total</td>
          {responseParams.map((item, index) => (
            <td
              key={index}
              style={{
                textAlign: item.dataType === "java.lang.Double" ? "right" : "center",
              }}
            >
             {item.showTotal
                ? data.data.reduce((sum, row) => {
                    const value = row[item.displayName];
                    if(item.dataType === "java.lang.Double") {
                      return sum + (value ? parseFloat(value) : 0);
                    } else {
                      return sum + (value ? parseInt(value, 10) : 0);
                    } // Convert to number and add
                  }, 0).toFixed(item.dataType === "java.lang.Double" ? 2 : 0) // Format to 2 decimal places for consistent display
                : ""}
            </td>
          ))}
        </tr> : <></>}
      </>
                ) : (
                <tr>
                  <td colSpan="6" style={{ textAlign: "center" }}>
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
            )}
          {/* Pagination */}
          {view !== 'chart' && 
          <ul className="pagination">
            <li className={`page-item previous ${currentPage === 1 ? "disabled" : ""}`}>
              <a
                href="#"
                className="page-link"
                onClick={(e) => {
                  e.preventDefault();
                  if (cursors.previous) handlePageClick(currentPage - 1, cursors.previous);

                }}>
                <i className="previous"></i>
              </a>
            </li>
             {/* {[...Array(totalPages)].map((_, index) => (
              <li
                key={index}
                className={`page-item ${currentPage === index + 1 ? "active" : ""}`}>
                <a
                  href="#"
                  className="page-link"
                  onClick={(e) => {
                    e.preventDefault();
                    handlePageClick(index + 1, null);
                  }}>
                  {index + 1}
                </a>
              </li>
            ))}  */}
{(() => {
    const totalPageLimit = 10; // Maximum number of pages to show at a time
    const startPage = Math.max(1, currentPage - Math.floor(totalPageLimit / 2));
    const endPage = Math.min(totalPages, startPage + totalPageLimit - 1);
    const adjustedStartPage = Math.max(1, endPage - totalPageLimit + 1);

    const pages = [];
    for (let i = adjustedStartPage; i <= endPage; i++) {
      pages.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`}
        >
          <a
            href="#"
            className="page-link"
            onClick={(e) => {
              e.preventDefault();
              handlePageClick(i, null);
            }}
          >
            {i}
          </a>
        </li>
      );
    }
    return pages;
  })()}
            <li className={`page-item next ${currentPage === totalPages ? "disabled" : ""}`}>
              <a
                href="#"
                className="page-link"
                onClick={(e) => {
                  e.preventDefault();

                  if (cursors.next) handlePageClick(currentPage + 1 , cursors.next);
                }} >
                <i className="next"></i>
              </a>
            </li>
          </ul>
           }
        
          </div>
  
          <div className="card-footer" style={{borderTop: "none"}}>
          <div 
      className="d-flex justify-content-end align-items-center" >
            <div className="text-center">
<div className="d-flex gap-2 min-w-100px">
          { !isChartAvailable && view !== 'chart' && 
                <>
                <label className="mt-3"> Records Per Page:   </label> 
                 <select
                  id="records-dropdown"
                  className="form-select w-auto"
                  value={defaultPageSize}
                  onChange={(e) =>{
                    handlePagesizeChange(Number(e.target.value))}}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={200}>200</option>
                  <option value={500}>500</option>


                </select>
                </>
          }       
          
                  
                    </div>
</div>
</div>
          </div></>}
        </div>
    </>
  );
}