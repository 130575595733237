import  { useContext} from 'react';
import { Link } from 'react-router-dom';
import { CurrentReportsContext } from './CurrentReportContext';

export default function ReportNavigation({token}){

    const {currentReportData} = useContext(CurrentReportsContext);
     
const groupedReports = Object.values(currentReportData)
.filter((report) => report.hide === false)
.reduce((acc, report) => {
    if (!acc[report.category]) {
        acc[report.category] = [];
    }
    acc[report.category].push(report);
    return acc;
}, {});

Object.keys(groupedReports).forEach((category) => {
    groupedReports[category].sort((a, b) => a.displayOrder - b.displayOrder);
});

    return(
        <div>
            <div>
            {Object.entries(groupedReports).map(([category, reports], index) => (
                    <li key={index} className='p-4' >
                        <strong>{category}</strong>
                         <div class="d-flex flex-column">
                            {reports.map((report) => (
                             <li className='d-flex align-items-center py-2'  key={report.reportID}>
                                <span class="bullet bullet-dot me-5 h-6px w-6px"></span> <Link to={report.reportID === 101 ? '/report/' : `/report/${report.reportDescription.replaceAll(" ", "-")}`}> {report.reportDescription}  </Link>
                             </li>        
                            ))}
                        </div>
                    </li>
                ))}  
                </div>
        </div>
    )
}