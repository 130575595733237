import React, { useEffect, useState, createContext } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { HeaderWrapper } from "../components/header";
import { Content } from "../components/content";
// import { FooterWrapper } from "../components/footer";
import { Sidebar } from "../components/sidebar";
import { reInitMenu } from "../helpers";
import { MasterComponentInit } from "./MasterComponentInit";



const MasterLayout : React.FC<{ token: string }> = ({ token }) =>{
  const location = useLocation();

  useEffect(() => {
    reInitMenu();
  }, [location.key]);


  return (
  <div className="d-flex flex-column flex-root app-root">
        <div className="app-page flex-column flex-column-fluid">
    
        <div className="app-wrapper flex-column flex-row-fluid">
          {/*Side Navbar*/}
          <Sidebar token={token}/>
          <div className="app-main flex-column flex-row-fluid">
            <div className="d-flex flex-column flex-column-fluid">
              <Content>
                <Outlet />
              </Content>
            </div>

            {/* <FooterWrapper /> */}

            <MasterComponentInit />
          </div>
        </div>
        </div>
  </div>
  );
};

export { MasterLayout };
