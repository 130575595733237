import React from "react";

const CommonLoading = ({ loadingText = "Loading data, please wait...", type="" }) => {
  if (type=="button"){
      return(<>{loadingText} <div className="ms-2 spinner-border text-white" style={{ width: "1rem", height: "1rem" }}></div></>);
  }
  return (
    <div className="d-flex flex-column flex-root">
      {/* Full-page Centered Loading */}
      <div className="d-flex flex-center flex-column flex-column-fluid" style={{minHeight:"300px"}}>
        <div className="text-center">
          <div className="spinner-border text-primary" style={{ width: "3rem", height: "3rem" }}></div>
          <p className="mt-3 fs-5 text-gray-700">{loadingText}</p>
        </div>
      </div>
    </div>
  );
};

export default CommonLoading;