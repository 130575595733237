import React, { useState } from "react";
import BarChart from "./BarChart";


function AxisDropdowns({data, reportName, reportResponseParams}) {

  const displayNames = reportResponseParams
  .filter(item => item.displayName && item.columnAvailableForGraph) // Filter objects that have a displayName field
  .map(item => item.displayName); // Extract the displayName values


  // Dynamically identify parameters for Y-axes
  const yAxisParams = {
    left: reportResponseParams.find(param => param.graphDisplayAxis === 'Y-axis-l'),
    right: reportResponseParams.find(param => param.graphDisplayAxis === 'Y-axis-r'),
  };

  const [leftAxisValue, setLeftAxisValue] = useState(yAxisParams.left ?.displayName || "Select an option" );
  const [rightAxisValue, setRightAxisValue] = useState(yAxisParams.right ?.displayName || "Select an option");
  console.log("yAxisParams left : ",leftAxisValue )
  console.log("yAxisParams right : ", rightAxisValue)



  const handleLeftChange = (e) => {
    setLeftAxisValue(e.target.value);
    // Clear the Right Y-Axis value if it becomes invalid
    if (e.target.value === rightAxisValue) {
      setRightAxisValue("");
    }
  };

  const handleRightChange = (e) => {
    setRightAxisValue(e.target.value);
    // Clear the Left Y-Axis value if it becomes invalid
    if (e.target.value === leftAxisValue) {
      setLeftAxisValue("");
    }
  };


  return (
    <div>
    <div className="card-header pb-1 pl-0 pr-0" >
      <div className="card-toolbar">
        {/* <label className="fs-6 fw-semibold mb-2">Left Y Axis</label> */}
        <select
          id="displayNamesLeft"
          className="form-select"
          value={leftAxisValue}
          onChange={handleLeftChange}
        >
          {displayNames.map((option, index) => (
            <option
              key={index}
              value={option}
              disabled={option === rightAxisValue}
            >
              {option}
            </option>
          ))}
        </select>
      </div>
      <div className="card-toolbar">
        {/* <label className="fs-6 fw-semibold mb-2">Right Y Axis</label> */}
        <select
          id="displayNamesRight"
          className="form-select"
          value={rightAxisValue}
          onChange={handleRightChange}
        >
          {displayNames.map((option,index) => (
            <option
              key={index}
              value={option}
              disabled={option === leftAxisValue}
            >
              {option}
            </option>
          ))}
        </select>
      </div>
    </div>
    <BarChart data={data} reportName={reportName} yAxisLeftParam={leftAxisValue} yAxisRightParam={rightAxisValue}/>
    </div>
  );
}

export default AxisDropdowns;